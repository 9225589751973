import { navigate, Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap"
import Image from "../image"
import Menu from "../menu"

const Header = ({ siteTitle, data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const handleDropdown = () => {
    const element = document.getElementById("dropdown-wrapper")
    if (element.style.display === "block") {
      navigate("/all-services")
      return
    }
    element.style.display = "block"
  }

  const toHome = () => navigate("/")

  return (
    <div>
      <Navbar
        className={isOpen ? "nav-dark" : "nav-dark"}
        color="light"
        light
        expand="lg"
      >
        <Container>
          <NavbarBrand>
            <div
              role="button"
              className="pointer"
              onClick={toHome}
              onKeyDown={toHome}
              tabIndex={0}
            >
              <Image src="logo-min.png" className="logo" alt="logo" />
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <div className="nav-link">
                  <Link
                    to="/"
                    className="h5 text-color-light pointer text-decoration-none"
                    role="button"
                  >
                    HOME
                  </Link>
                </div>
              </NavItem>
              <NavItem>
                <div className="nav-link">
                  <Link
                    className="h5 text-color-light pointer text-decoration-none"
                    to="/about-us"
                  >
                    ABOUT US
                  </Link>
                </div>
              </NavItem>
              <NavItem className="dropdown-wrapper">
                <NavLink>
                  <div
                    className="h5 text-color-light pointer dropbtn"
                    role="button"
                    id="category-btn"
                    onClick={handleDropdown}
                  >
                    CATEGORIES
                  </div>
                </NavLink>
                <Menu data={data} />
              </NavItem>
              <NavItem>
                <div className="nav-link">
                  <Link
                    className="h5 text-color-light pointer text-decoration-none"
                    to="/contact"
                  >
                    CONTACT
                  </Link>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
